import React from 'react';
import { Cookie } from '@silkpwa/module/cookie/cookie';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';
import { usePortalInfo } from '@silkpwa/module/portal';
import { ProductListingPage } from 'ui/component/product-listing-page';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { connectCategory } from '@silkpwa/module/react-component/connect-category';
import { Breadcrumbs } from 'ui/component/breadcrumbs';
import { Container } from 'ui/component/container';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { SEOTags } from 'ui/component/seo-tags';
import { createConnectPagination } from '@silkpwa/module/react-component/create-connect-pagination';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { HeroBanner } from './hero-banner';
import styles from './style.css';

const ProductListing = createConnectPagination((modules: {[key: string]: any}) => {
    const ecommerceCategory = modules.ecommerceCategory as EcommerceCategory;
    return ecommerceCategory.ducks.pagination;
})(ProductListingPage);

const CategoryPageImpl = ({ category }) => {
    const t = usePhraseTranslater();
    const { portal } = usePortalInfo();
    const isActivePortal = Boolean(portal?.is_active && portal.code);
    /**
     * We have to be sure that when a customer lands on a PLP:
     * 1. the `current category id` should be memorized in cookies in order to be used on the BE for retrieving
     * the correct category and return correct breadcrumbs array, see:
     *  - API: app_product_info;
     *  - Observer: Silk\Restapp\Observer\InjectBreadcrumbsObserver.
     * 2. Breadcrumbs logic is in the `pwa-theme/packages/chefworks-theme/src/ui/component/breadcrumbs/breadcrumbs.tsx`
     */
    const cookie = new Cookie();
    cookie.set('category-id', category.id, 7);
    return (
        <div data-page-type="category">
            <DocumentTitle>{category.meta_title || category.name}</DocumentTitle>
            <SEOTags info={category} />
            <Breadcrumbs path={category.breadcrumbs} />
            <div className={styles.categoryPage}>
                {!isActivePortal && category.description && category.image ? (
                    <HeroBanner category={category} />
                ) : null }
                <MainColumn className="maincolumn-categorypage">
                    <Container id="content.top" />
                    <ProductListing
                        key={category.id}
                        shouldDisplaySort
                        shouldShowRecommendations={false}
                        emptyContent={(
                            <div data-test="no-product-box">
                                <h1 className={styles.h1Style}>{t('No Products')}</h1>
                                <CertonaRecommendations />
                            </div>
                        )}
                    />
                </MainColumn>
            </div>
        </div>
    );
};

const CategoryPage = connectCategory(CategoryPageImpl);

export { CategoryPage as default };
