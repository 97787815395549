import React from 'react';
import { Breadcrumbs } from '@silkpwa/module/react-component/breadcrumbs';
import { Link } from 'ui/component/link';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { BreadcrumbRichSnippet } from '../rich-snippets/breadcrumb-rich-snippet';
import styles from './breadcrumbs.css';

const Crumb = ({ url, label }) => (
    <Link href={url}>{label}</Link>
);

const LastCrumb = ({ label }) => (
    <span className={styles.last}>{label}</span>
);

const Separator = _ => (
    <div className={styles['breadcrumb-separator']} />
);

const Wrapper = ({ children }) => (
    <div className={styles.breadcrumbs}>
        <Crumb url="/" label="Home" />
        <Separator />
        {children}
    </div>
);

const StyledBreadcrumbs: React.FC<{path: Array<string|{label: string; url: string}>}> = ({ path }) => {
    /**
     * The `search filter params` are put into the `sessionStorage` when a customer clicks on the Product Card on a PLP
     * in order to memorize setup filters (GET params), e.g.: `?color_filters=998`
     */
    const searchFilter = window.sessionStorage.getItem('searchFilter');
    let modifiedPath = path.map(item => (typeof item === 'string' ? { label: item, url: '' } : item));

    /**
     * Here, IF THERE ARE `searchFilter` params - they are retrieved in order to add them to a category breadcrumbs url,
     * which allows to return a customer to the PLP with a pre-set previously filters, e.g.:
     *  - `/half-aprons?color_filters=998` instead of `/half-aprons`
     */
    if (searchFilter) {
        modifiedPath = modifiedPath.map((url, index) => {
            if (index === modifiedPath.length - 1) {
                return url;
            }

            return { label: url.label, url: url.url + searchFilter };
        });
    }

    const { isOverrideCategories } = useOverrideCategoriesInfo();
    if (isOverrideCategories) {
        return null;
    }

    return (
        <>
            <BreadcrumbRichSnippet path={modifiedPath} />
            <Breadcrumbs
                Wrapper={Wrapper}
                Crumb={Crumb}
                LastCrumb={LastCrumb}
                Separator={Separator}
                path={modifiedPath}
            />
        </>
    );
};

export { StyledBreadcrumbs as Breadcrumbs };
