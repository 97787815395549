import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

// The code is easier to read for this particular component if we don't enforce these rules.
/* eslint-disable jsx-a11y/anchor-has-content, react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
export const Link = props => (
    <a
        className={classes(styles.link, props.className)}
        {...props}
    />
);
/* eslint-enable */
