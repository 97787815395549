import React from 'react';
import { currentBrowser } from 'ui/util/current-browser';
import { Description } from './description';
import { BannerImage } from './banner-image';
import { IBreadCrumb } from './color-resolver';
import ieStyles from './iestyle.css';
import regularStyles from './style.css';

const styles = (() => {
    if (currentBrowser === 'IE') {
        return ieStyles;
    }
    return regularStyles;
})();

interface ICategory {
    breadcrumbs: IBreadCrumb[];
    name: string;
    description: string;
    image: string;
    textBackgroundColor: string;
}
function getHeroBannerColor(color) {
    return {
        backgroundColor: color,
    };
}

export const HeroBanner: React.FC<{ category: ICategory }> = ({ category }) => (
    <div className={styles.heroBanner} style={getHeroBannerColor(category.textBackgroundColor)}>
        <div className={styles.heroBannerDescription}>
            <Description category={category} />
        </div>
        <div className={styles.heroBannerImageWrap}>
            <BannerImage category={category} className={styles.heroBannerImage} />
        </div>
    </div>
);
