import React from 'react';
import { PlaceholderImage } from 'ui/component/placeholders/image';
import screenSwitch from 'ui/styles/screen-switch.css';

export const BannerImage = ({ category, className }) => (
    <React.Fragment>
        {category.image && (
            <img
                src={category.image}
                alt=""
                className={className}
                width="400"
                height="300"
            />
        )}
        {!category.image && (
            <React.Fragment>
                <PlaceholderImage width="100%" height="400px" className={screenSwitch.showOnDesktop} />
                <PlaceholderImage width="100%" height="130px" className={screenSwitch.showOnMobile} />
            </React.Fragment>
        )}
    </React.Fragment>
);
