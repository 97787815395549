import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Wishlist } from '../../components/wishlist';
import { Compare } from '../../components/compare';
import { MobileAccordion } from '../../components/mobile-accordion';
import styles from './styles.css';

export const MobileBottomContent = () => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.mobileBottomContent}>
            <MobileAccordion text={t('My Wishlist')}>
                <Wishlist />
            </MobileAccordion>
            <MobileAccordion text={t('Compare Products')}>
                <Compare />
            </MobileAccordion>
        </div>
    );
};
