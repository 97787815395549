import React from 'react';
import { RichSnippet } from './rich-snippet';

interface IPath {
    label: string;
    url: string;
}

interface IBreadcrumbRichSnippetProps {
    path: IPath[];
}

interface IBreadcrumbListItem {
    '@id': string;
    name: string;
}

interface IBreadcrumbListElement {
    '@type': string;
    position: number;
    item: IBreadcrumbListItem;
}

export interface IBreadcrumbSnippetData {
    '@context': string;
    '@type': string;
    itemListElement: IBreadcrumbListElement[];
}

const getCrumbs = (path: IPath[]) => path.map((item: IPath, index = 2) => (
    {
        '@type': 'ListItem',
        position: index + 2,
        item: {
            '@id': item.label,
            name: item.label,
        },
    }
));

/**
 * Adds the breadcrumb rich snippet (https://schema.org/BreadcrumbList) to the frontend of the website
 * @param path
 * @constructor
 */

export const BreadcrumbRichSnippet: React.FC<IBreadcrumbRichSnippetProps> = ({ path }) => {
    const breadcrumbData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': 'Home',
                    name: 'Home',
                },
            },
        ].concat(getCrumbs(path)),
    };
    return <RichSnippet data={JSON.stringify(breadcrumbData)} />;
};
