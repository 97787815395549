import React from 'react';

export interface IBreadcrumb {
    label: string;
}

export const Breadcrumbs = ({
    path,
    Wrapper,
    LastCrumb,
    Crumb,
    Separator,
}) => (
    <Wrapper>
        {path.reduce((acc, p, i) => {
            if (i === path.length - 1) {
                acc.push(<LastCrumb key={p.label} label={p.label} url={p.url} />);
            } else {
                acc.push(<Crumb key={p.label} label={p.label} url={p.url} />);
                acc.push(<Separator key={`crumb-separator${i - 1}`} />);
            }

            return acc;
        }, [])}
    </Wrapper>
);
