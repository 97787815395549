import React from 'react';
import { PlaceholderSentence } from 'ui/component/placeholders/sentence';
import { PlaceholderLastSentence } from 'ui/component/placeholders/last-sentence';

const RenderDescription = ({ description }) => (
    // This is coming from content in the CMS, so it is fine.
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: description }} />
);

const hasHeader = value => !!value.match(/<h1>.*<\/h1>/);

export const Description = ({ category: { name, description } }) => {
    if (!description) {
        return (
            <div>
                <h1>{name}</h1>
                <PlaceholderSentence />
                <PlaceholderSentence />
                <PlaceholderLastSentence />
            </div>
        );
    }
    // the user has put a header so they are
    // overriding the category name, don't explicitly show it
    if (hasHeader(description || '')) {
        return (
            <RenderDescription description={description} />
        );
    }
    // add category name above the description
    return (
        <div>
            <h1>{name}</h1>
            {<RenderDescription description={description} />}
        </div>
    );
};
