import React from 'react';
import { useScrollOnNavigation } from 'hooks/use-scroll-on-navigation';
import scrollTarget from 'ui/styles/scroll-target.css';
import { DesktopLeftContent } from './sections/desktop-left-content';
import { MobileTopContent } from './sections/mobile-top-content';
import { MainContent } from './sections/main-content';
import { MobileBottomContent } from './sections/mobile-bottom-content';
import styles from './styles.css';


export const ProductListingPage = ({
    pageState,
    urls,
    actions,
    shouldDisplaySort,
    shouldShowRecommendations,
    emptyContent,
}) => {
    const scrollRef = useScrollOnNavigation();

    return (
        <div
            className={scrollTarget.hack}
            ref={scrollRef as React.MutableRefObject<HTMLDivElement>}
        >
            <div className={styles.content}>
                <DesktopLeftContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                />
                <MobileTopContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                />
                <MainContent
                    pageState={pageState}
                    urls={urls}
                    actions={actions}
                    shouldDisplaySort={shouldDisplaySort}
                    shouldShowRecommendations={shouldShowRecommendations}
                    emptyContent={emptyContent}
                />
                <MobileBottomContent />
            </div>
        </div>
    );
};
